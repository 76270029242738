//React
import * as React from 'react';
import { useState, useEffect, useRef } from 'react';

//Gatsby
import { navigate, Link } from 'gatsby';
// import { GatsbyImage } from 'gatsby-plugin-image';
// import { MDXRenderer } from 'gatsby-plugin-mdx';

//Styles
import * as styles from './WorkExample.module.scss';

//Components
import Layout from '../../components/Layout/Layout';
import Card from '../../components/Card/Card';


const PAGE_NAMES = [
  'mkrenovations',
  'superautopets',
  'burgerbuilder',
  'spacea',
  'mtcleverest',
  'chris180',
  'nattypetshop'
]

const WorkExample = ({ pageName, cardColor, title, subtitle, imageData, imageAlt, links, location, children }) => {
  const [animation, setAnimation] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const currentPageName = pageName;
  const contentRef = useRef(null);
  const imageRef = useRef(null);

  useEffect(()=>{
    if (imageRef.current?.complete) {
      showCard();
     }
    if (location.state) {
      switch (location.state.direction) {
        case 'next':
          setAnimation(styles.initial);
          break;
        case 'prev':
          setAnimation(styles.initial);
          break;
        default:
          setAnimation(styles.initial);
      }
    }
    else{
      setAnimation(styles.initial);
    }
  }, [])
 

  const nextProject = () => {
    const nextPage = PAGE_NAMES.indexOf(currentPageName) === PAGE_NAMES.length - 1 ?
      `/my-work/${PAGE_NAMES[0]}` :
      `/my-work/${PAGE_NAMES[PAGE_NAMES.indexOf(currentPageName) + 1]}`;
    setAnimation(styles.exitNext)
    setTimeout(() => {
      navigate(
        nextPage,
        {
          state: { direction: 'next' }
        }
      )
    }, 200)

  }

  const prevProject = () => {
    const prevPage = PAGE_NAMES.indexOf(currentPageName) === 0 ?
      `/my-work/${PAGE_NAMES[PAGE_NAMES.length - 1]}` :
      `/my-work/${PAGE_NAMES[PAGE_NAMES.indexOf(currentPageName) - 1]}`;
    setAnimation(styles.exitPrev)
    setTimeout(() => {
      navigate(prevPage,
        {
          state: {
            direction: 'prev'
          }
        })
    }, 200)
  }

  const showCard = ()=>{
    setLoaded(true);
  }

  return (
    <Layout location={location}>
      <div className={styles.overflowWrapper}>
      <div ref = {contentRef} className={`${styles.content} ${animation && loaded ? animation : ''} `}>
      <Card addedClasses={[styles.workExampleCard]}>
        <div className={styles.cardHeader} style={{backgroundColor: `${cardColor}`}}>
          <Link to="/my-work">X</Link>
        <div className={styles.headerImageWrapper}>
            <img src={imageData} alt={imageAlt} ref={imageRef} onLoad={showCard}></img>
        </div>
       
          <div className={styles.cardHeaderText}>
            <h1>{title}</h1>
            <p>{subtitle}</p>
          </div>
        </div>
         <div className={styles.cardContent}>
            {children}
          {links.length > 0 && <div className={styles.links}>
            {links.map((link)=>{
              if (link.external){
                return(
                  <a href={`${link.url}`} key={link.url}target='__blank' rel="noopener noreferrer" style={{backgroundColor: `${cardColor}`}}>{`${link.text}`}</a>
                )
              } else {
                return (
                  <Link to={`${link.url}`} style={{backgroundColor: `${cardColor}`}}>{`${link.text}`}</Link>
                )
              }
             
            })}
          </div>}
          </div>
        </Card>
      </div>
      <div className={styles.navArrows}>
        <div className={styles.arrowHead} onClick={prevProject}>
          <div></div>
          <div></div>
          <p>Previous</p>
        </div>
        <div className={`${styles.arrowHead} ${styles.flipped}`} onClick={nextProject}>
          <div></div>
          <div></div>
          <p>Next</p>
        </div>
      </div>
      </div>
    </Layout>
  )
}
export default WorkExample;