import * as React from 'react';
import * as styles from './burgerbuilder.module.scss';
import WorkExample from '../../../components/WorkExample';

//Components
import Seo from '../../../components/seo';

//Images
import burgerBuilderHeaderImage from '../../../images/work-example-headers/burger-builder.png';

const BurgerBuilder = ({location})=>{
    return(
        <WorkExample
            pageName={'burgerbuilder'}
            cardColor={'#a75a27'}
            title={'Burger Builder'}
            subtitle={"A simple food order app with Firebase authentication"}
            imageData={burgerBuilderHeaderImage}
            imageAlt={"Burger Builder"}
            links={[
                {text: "Check out the project",url: "https://burger-builder-a7529.web.app/build", external: true},
                // {text: "View the code on Github", url: "https://github.com/dkolonay/burger-builder"}
            ]}
            location={location}
        >
            <Seo
                title={"Burger Builder"}
                description={"A simple food order app with Firebase authentication"}
            />

            

            <h2>About the Project</h2>
            <p>Burger Builder is a class project I worked on while learning React.  The project is designed as a showcase of my skills in React state management with a Firebase backend database.</p>

            <h2>What I did</h2>
            <ul>
                <li>Created a portal for user authentication</li>
                <li>Designed a data submission form that syncs between React Redux, local storage, and Google Firebase</li>
            </ul>
        </WorkExample>
    )
}

export default BurgerBuilder;