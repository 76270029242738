// extracted by mini-css-extract-plugin
export var arrowHead = "WorkExample-module--arrowHead--29b24";
export var cardContent = "WorkExample-module--cardContent--f5192";
export var cardHeader = "WorkExample-module--cardHeader--0f4d2";
export var cardHeaderText = "WorkExample-module--cardHeaderText--3d05c";
export var content = "WorkExample-module--content--1b1d6";
export var enterNext = "WorkExample-module--enterNext--def56";
export var enterPrev = "WorkExample-module--enterPrev--400e2";
export var exitNext = "WorkExample-module--exitNext--12437";
export var exitPrev = "WorkExample-module--exitPrev--ad1b6";
export var fadeInLeft = "WorkExample-module--fadeInLeft--9d044";
export var fadeInRight = "WorkExample-module--fadeInRight--a06c1";
export var fadeOutLeft = "WorkExample-module--fadeOutLeft--f672a";
export var fadeOutRight = "WorkExample-module--fadeOutRight--47389";
export var fadeUp = "WorkExample-module--fadeUp--19e4a";
export var flipped = "WorkExample-module--flipped--57efd";
export var headerImageWrapper = "WorkExample-module--headerImageWrapper--24d03";
export var initial = "WorkExample-module--initial--005ce";
export var links = "WorkExample-module--links--2c608";
export var navArrows = "WorkExample-module--navArrows--ad218";
export var overflowWrapper = "WorkExample-module--overflowWrapper--bd14f";
export var workExampleCard = "WorkExample-module--workExampleCard--cb5cc";